// extracted by mini-css-extract-plugin
export var antSteps = "index-module--ant-steps--aRSYz";
export var antStepsDot = "index-module--ant-steps-dot--H6nXD";
export var antStepsHorizontal = "index-module--ant-steps-horizontal--mu+1t";
export var antStepsItemDescription = "index-module--ant-steps-item-description--h8vJa";
export var antStepsItemIcon = "index-module--ant-steps-item-icon--h21lq";
export var antStepsItemTitle = "index-module--ant-steps-item-title--njZ3R";
export var antStepsSmall = "index-module--ant-steps-small--R41zA";
export var antTypography = "index-module--ant-typography--Oa6Z8";
export var badges = "index-module--badges--QFtyM";
export var docServiceCarousel = "index-module--docServiceCarousel--WkxZF";
export var getStarted = "index-module--getStarted--f72UW";
export var getStartedButton = "index-module--getStartedButton--leXE6";
export var hideIfShrunk = "index-module--hideIfShrunk--OncZt";
export var hideOnReducedMotion = "index-module--hideOnReducedMotion--DjEC7";
export var indented = "index-module--indented--jqvKK";
export var noBullet = "index-module--noBullet--S4Qo9";
export var responsive = "index-module--responsive--2S+XA";
export var showOnReducedMotion = "index-module--showOnReducedMotion--X5H1F";
export var slogan = "index-module--slogan--7d19q";
export var sloganBackgroundImage = "index-module--sloganBackgroundImage--4a+41";
export var testimonial = "index-module--testimonial--7JYuz";
export var tweetBox = "index-module--tweet-box--qAM4g";
export var wrapper = "index-module--wrapper--rKdDH";