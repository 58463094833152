// extracted by mini-css-extract-plugin
export var antSteps = "project-badge-module--ant-steps--joYsH";
export var antStepsDot = "project-badge-module--ant-steps-dot--QkFu6";
export var antStepsHorizontal = "project-badge-module--ant-steps-horizontal--TIvgC";
export var antStepsItemDescription = "project-badge-module--ant-steps-item-description---szLI";
export var antStepsItemIcon = "project-badge-module--ant-steps-item-icon--2RzTe";
export var antStepsItemTitle = "project-badge-module--ant-steps-item-title--cJk+d";
export var antStepsSmall = "project-badge-module--ant-steps-small--p2+X5";
export var antTypography = "project-badge-module--ant-typography--+bdtG";
export var badge = "project-badge-module--badge--idwxc";
export var hideOnReducedMotion = "project-badge-module--hideOnReducedMotion--Z5L6M";
export var noBullet = "project-badge-module--noBullet--77cor";
export var showOnReducedMotion = "project-badge-module--showOnReducedMotion--NQgs6";
export var tweetBox = "project-badge-module--tweet-box--xc7Co";