// extracted by mini-css-extract-plugin
export var antSteps = "blockquote-module--ant-steps--no8oE";
export var antStepsDot = "blockquote-module--ant-steps-dot--a5wT+";
export var antStepsHorizontal = "blockquote-module--ant-steps-horizontal--baJp+";
export var antStepsItemDescription = "blockquote-module--ant-steps-item-description--SZXW7";
export var antStepsItemIcon = "blockquote-module--ant-steps-item-icon--IMMUK";
export var antStepsItemTitle = "blockquote-module--ant-steps-item-title--8e20Q";
export var antStepsSmall = "blockquote-module--ant-steps-small--HOGWq";
export var antTypography = "blockquote-module--ant-typography--Xya71";
export var author = "blockquote-module--author--+gLAL";
export var backgroundPattern1 = "blockquote-module--backgroundPattern1--wdy-N";
export var backgroundPattern1Reverse = "blockquote-module--backgroundPattern1Reverse--IXvc6";
export var backgroundPattern2 = "blockquote-module--backgroundPattern2--Ajvdk";
export var backgroundPattern2Reverse = "blockquote-module--backgroundPattern2Reverse--ZoJHY";
export var blockquote = "blockquote-module--blockquote--oCBb9";
export var body = "blockquote-module--body--ZEeaY";
export var from = "blockquote-module--from--4AGBe";
export var hideOnReducedMotion = "blockquote-module--hideOnReducedMotion--dylzn";
export var noBullet = "blockquote-module--noBullet--1YEjf";
export var quote = "blockquote-module--quote--h1UNU";
export var quotePosition = "blockquote-module--quotePosition--RHk0b";
export var separator = "blockquote-module--separator--mgsYn";
export var showOnReducedMotion = "blockquote-module--showOnReducedMotion--BAxBN";
export var tweetBox = "blockquote-module--tweet-box--ugrQV";