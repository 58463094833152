// extracted by mini-css-extract-plugin
export var antSteps = "marketing-module--ant-steps--PAzhy";
export var antStepsDot = "marketing-module--ant-steps-dot--OxjTl";
export var antStepsHorizontal = "marketing-module--ant-steps-horizontal--Grs5i";
export var antStepsItemDescription = "marketing-module--ant-steps-item-description--d6iVa";
export var antStepsItemIcon = "marketing-module--ant-steps-item-icon--lGO80";
export var antStepsItemTitle = "marketing-module--ant-steps-item-title--9SgtU";
export var antStepsSmall = "marketing-module--ant-steps-small--7o4ZC";
export var antTypography = "marketing-module--ant-typography--yUXRs";
export var block = "marketing-module--block--rnBB8";
export var blockList = "marketing-module--blockList--a2Gqn";
export var blockListReverse = "marketing-module--blockListReverse--cF7PL";
export var hideOnReducedMotion = "marketing-module--hideOnReducedMotion--tg2QK";
export var highlight = "marketing-module--highlight--CyQ1g";
export var highlightNoWrap = "marketing-module--highlightNoWrap--NNQXT";
export var noBullet = "marketing-module--noBullet--gLTS3";
export var showOnReducedMotion = "marketing-module--showOnReducedMotion--foT+M";
export var tweetBox = "marketing-module--tweet-box--GoXpG";
export var wrapper = "marketing-module--wrapper--x6KGV";